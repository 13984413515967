import React, { useState } from 'react';
import '../styles/History.scss';
import leftArrow from '../images/left-arrow.svg';
import rightArrow from '../images/right-arrow.svg';
import videoMain from '../video/Video_main.mp4';
import arrowBold from '../images/right-arrow-bold.svg';
import carousel from '../data/history-carousel.js';
import videoPopup from '../video/video_popup.mp4';
import closeButton from '../images/Close.svg';

export default function History() {
    let images = carousel;
    const [count, setCount] = useState(0)
    const [openVideo, setOpenVideo] = useState(false)

    

    function nextPhoto(e) {
        e.preventDefault()
        if (count === images.length - 1) {
            setCount(0)
        } else setCount(count + 1);

    }
    function prevPhoto(e) {
        e.preventDefault()
        if (count === 0) {
            setCount(images.length - 1)
        } else setCount(count - 1);
    }

    return (

        <div id="history" className='history__wrapper'>
            <img style={openVideo ? { display: 'block' } : { display: 'none' }} className={'closeButtonVideo'} src={closeButton} onClick={() => setOpenVideo(false)}></img>
            <div className='history__video'>
            
                {openVideo === true ? <video src={videoPopup} autoPlay controls></video> : <video src={videoMain} autoPlay loop muted playsInline></video>}
                <div className='history__video-title'>
                    <h2 style={openVideo ? { display: 'none' } : { display: 'block' }}>От честного <br />сердца</h2>
                    <div className='history__video-button'>
                        <button onClick={() => setOpenVideo(true)} style={openVideo ? { display: 'none' } : { display: 'flex' }}> <img alt='Стрелка' src={arrowBold} />  </button>
                        <p style={openVideo ? { display: 'none' } : { display: 'block' }}>Видеоролик <br />о ферме</p>
                    </div>
                </div>
            </div>
            <div className='history__info'>
                <div className='info__text'>
                    <h2> Крестьянское <br />фермерское <br /> хозяйство <br /> семьи Лычкиных </h2>
                    <p> Одной из особенностей и главным преимуществом хозяйства
                        является собственное выращивание цыплят на зерновом откорме,
                        что позволяет соблюдать баланс питательных веществ, делая конечный продукт
                        не только вкусным, но и полезным. </p>
                    <p> Наша продукция регулярно проходит все необходимые экспертизы,
                        которые подтверждают соответствие ГОСТу и ТР ТС,
                        сохраняя высокое качество фермерской птицы.</p>
                </div>
                <div className='info__photo'>
                    <img alt='Фото' className='info__photos' src={images[count]} />
                    <div className='button__wrapper'>
                        <button onClick={prevPhoto}>
                            <img src={leftArrow} alt="<" />
                        </button>
                        <button onClick={nextPhoto}>
                            <img src={rightArrow} alt=">" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}