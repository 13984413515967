import icon from '../images/logos/tabris.svg'
let citites = [
    {
        name: "Краснодар",
        markets: [
            {
                name: "Табрис",
                street: [
                    'Красных Партизан ул, дом 173',
                    'Ставропольская ул, дом 213',
                    'им. 40-летия Победы ул, дом 144/5',
                    'Чекистов пр-кт, дом 1/3',
                    'ул. Кореновская, д. 2',
                    'ул. Красная, дом 202, помещение 28',
                    'ул. Красных партизан 67',
                    'Ставропольская ул, дом 222',
                    'им. Тургенева ул, дом 138/6',
                    'Кубанская Набережная ул, дом 25',
                    'ул им. Петра Метальникова, д. 32/1',
                    'Сормовская ул, дом 108/1',
                    'Московская ул, дом 54',
                ]
            },
            {
                name: "Магазин МясникЪ",
                street: [
                    "ул. Им. Александра Покрышкина, д. 4/7"
                ]
            },
            {
                name: "Магазин Деревенька",
                street: [
                    "ул. Им. Александра Покрышкина, д. 4/5"
                ]
            },
            {
                name: "Магазин Мясо Индейки ",
                street: [
                    "ул. Дзержинского, д. 147"
                ]

            },
            {
                name: "Магазин Мясная лавка",
                street: [
                    "ул. 5-Дорожная, д. 70",
                    "ул. Душистая, д. 22/1"
                ]
            },
            {
                name: "Магазин Мясной ",
                street: [
                    "ул. Котовского, д. 86",
                    "ул. Им. Брюсова, д. 2"
                ]
            },
            {
                name: "Мясной павильон",
                street: [
                    "ул. Атарбекова, д. 1",
                    "пр. Чекистов, д.17"
                ]
            },
            {
                name: "Магазин Фермерская лавка у дома",
                street: [
                    "ул. Кожевенная, д. 28"
                ]
            },
            {
                name: "Магазин Подворье",
                street: [
                    "ул. Кожевенная, д. 58"
                ]
            },
            {
                name: "Магазин Продукты",
                street: [
                    " ул. Красных Партизан, д. ¼ корп. 8"
                ]
            },
            {
                name: "Магазин Мясо индейки",
                street: [
                    "Магазин Мясо индейкиул. 2-я Целиноградская, д. ½"
                ]
            },
            {
                name: " МясоLove",
                street: [
                    "ул. Российская, д. 267/3, корп. 1"
                ]
            },
            {
                name: "Магазин Фасоль",
                street: [
                    "ул. Им. Чернышевского, д. 1/3",
                    "ул. Янковского, д. 137"

                ]
            },
            {
                name: "Магазин Фасолька",
                street: [
                    "ул. Буденного, д. 334"
                ]
            },
            {
                name: "Магазин Мясной",
                street: [
                    "ул. Димитрова, д. 13"
                ]
            },
            {
                name: "Магазин Ассорти",
                street: [
                    "ул. Кружевная, д. 3"
                ]
            },
            {
                name: "Магазин Фермерский дворик",
                street: [
                    "ул. Валерия Гассия, д. 2, пом. 3"
                ]
            },
            {
                name: "Магазин МЯСОед",
                street: [
                    "ул. Уральская, д. 152/1"
                ]
            },
            {
                name: "Магазин Фермерская лавка",
                street: [
                    "ул. Тюляева, д. 3/2"
                ]
            },
            {
                name: "Ярмарки выходного дня по субботам (лавки ИП Г К(Ф)Х Лычкин А. В.)",
                street: [
                    "пр. Чекистов, д. 9",
                    "ул. Им. Будённого (между ул. Рашпилевская и ул. Красная)",
                    "ул. Одесская, д. 48"
                ]
            }
        ]
    },
    {
        name: "Сочи",
        markets: [
            {
                name: "Табрис",
                street: [
                    'р-н Центральный, ул Транспортная, д. 28',
                ]
            }
        ]
    },
    {
        name: "Анапа",
        markets: [
            {
                name: "Табрис",
                street: [
                    'ул Астраханская, д. 108',
                ]
            }
        ]
    },
    {
        name: "Геленджик",
        markets: [
            {
                name: "Табрис",
                street: [
                    'ул Мира, д. 44, литер 1'
                ]
            }
        ]
    },
    {
        name: "Новороссийск",
        markets: [
            {
                name: "Табрис",
                street: [
                    'пр-кт Ленина, д. 7А',
                    
                ]
            }
        ]
    },
    {
        name: "п. Знаменский",
        markets: [
            {
                name: "Магазин Мясное подворье",
                street: [
                    'ул. Богатырская, д. 148',
                ]
            }
        ]
    },
    {
        name: "ст. Калининская",
        markets: [
            {
                name: "Магазин Мясная Лавка",
                street: [
                    'ул. Ленина, д. 152, офис 4',
                    'ул. Ленина, д. 125',
                ]
            }
        ]
    },
    {
        name: "ст. Старовеличковская",
        markets: [
            {
                name: "Магазин Мясная Лавка",
                street: [
                    'ул. Красная, д. 200'
                ]
            }
        ]
    },
    {
        name: "Тимашевск",
        markets: [
            {
                name: "Магазин Свежее мясо",
                street: [
                    'ул. Пролетарская, д. 116'
                ]
            },
            {
                name: "Магазин Perfetto",
                street: [
                    "п. Садовод, д. 2, стр. Г"
                ]
            },
            {
                name: "Магазин Благодать",
                street: [
                    "СНТ Индустриальный, д. 5а/4"
                ]
            },
            {
                name: "Магазин Продукты",
                street: [
                    "МКР Садовод, ул. 70 лет Октября, д. 41"
                ]
            },
            {
                name: "Магазин Лавка Фермера",
                street: [
                    " ул Пролетарская, д. 150"
                ]
            }
        ]
    },
    {
        name: "х. Беднягина",
        markets: [
            {
                name: "Магазин Иволга",
                street: [
                    'ул. Коммунистическая, д. 3, стр. Б',
                ]
            }
        ]
    },
    {
        name: "ст. Медведовская",
        markets: [
            {
                name: "Магазин Мясной король",
                street: [
                    'ул. Ленина, д.73'
                ]
            },
            {
                name: "Магазин Конфетка",
                street: [
                    "ул. Ленина, д. 111 А"
                ]
            },
            {
                name: "Магазин Мясной дворик",
                street: [
                    "ул. Мира, д. 142 А"
                ]
            }
        ]
    },
    {
        name: "ст. Новотитаровская",
        markets: [
            {
                name: "Магазин Свежее мясо",
                street: [
                    'ул. Крайняя, д. 18',
                ]
            },
            {
                name: "Магазин Сытый Гном",
                street: [
                    "ул. Краснодарская, д. 50"
                ]
            },
            {
                name: "Магазин Индейка",
                street: [
                    "ул. Советская, д. 66"
                ]
            },
            {
                name: "Магазин Деревенская курочка ",
                street: [
                    "ул. Советская, д. 87"
                ]

            }
        ]
    },
    {
        name: "ст. Старовеличковская",
        markets: [
            {
                name: "Магазин Мясо",
                street: [
                    'ул. Городская, д. 18',
                ]
            }
        ]
    },
    {
        name: "п. Берёзовый",
        markets: [
            {
                name: "Магазин Свежее мясо",
                street: [
                    'ул. Ейское шоссе, д. 9, стр. 1',
                ]
            }
        ]
    },
    {
        name: "п. Южный",
        markets: [
            {
                name: "Магазин Деревенская курочка",
                street: [
                    'ул. Мира, д. 37/2 А',
                ]
            }
        ]
    },
    {
        name: "ст. Бриньковская",
        markets: [
            {
                name: "Магазин Курочка Ряба",
                street: [
                    'ул. Красная, д. 73/2',
                ]
            }
        ]
    },
    {
        name: "ст. Ольгинская",
        markets: [
            {
                name: "Магазин Курочка Ряба",
                street: [
                    'ул. Ленина, д. 56/1',
                ]
            }
        ]
    },
    {
        name: "ст. Новоджерелиевская",
        markets: [
            {
                name: "Магазин Курочка Ряба",
                street: [
                    'ул. 89 Стрелковой Дивизии, д. 46 Б',
                ]
            }
        ]
    },
    {
        name: "ст. Каневская",
        markets: [
            {
                name: "Магазин Фермер",
                street: [
                    'ул. Горького, д. 214 Ж',
                    'ул. Черноморская, д. 55 А'
                ]
            },
            {
                name: "Магазин Лавка Фермера",
                street: [
                    'ул. Кубанская, д. 45, стр. Б',
                    'Азовская ул, дом 75',
                    'ул. Широкая, д. 2/1',
                ]
            }
        ]
    },
    {
        name: "ст. Ленинградская",
        markets: [
            {
                name: "Магазин Фермер",
                street: [
                    'ул. Красная, д. 111',
                ]
            }
        ]
    },
    {
        name: "ст. Брюховецкая",
        markets: [
            {
                name: "Магазин Лавка Фермера",
                street: [
                    'ул. Кирова, д. 121',
                ]
            }
        ]
    },
]
export default citites