import React from 'react';
import { useState} from 'react';
import leftArrow from '../images/left-arrow.svg';
import rightArrow from '../images/right-arrow.svg';
import '../styles/Cooperation.scss';
import carousel from '../data/cooperation-carousel'
import staff from '../data/staff';
import { ReactComponent as Arrow } from '../images/Strelka_right_mobile.svg';

export default function Cooperation({width}) {
    const [lastPerson, setLastPerson] = useState(2)
    const [firstPerson, setFirstPerson] = useState(0)
    let images = carousel;
    let staffArray = staff;
    let staffArrayView = staffArray.slice(firstPerson, lastPerson)
    const [count, setCount] = useState(0)
    
    

    
    function nextPerson(e) {
        setFirstPerson(firstPerson + 1)
        setLastPerson(lastPerson + 1)

    }
    function prevPerson(e) {
        setFirstPerson(firstPerson - 1)
        setLastPerson(lastPerson - 1)
    }

    function nextPhoto(e) {
        e.preventDefault()
        if (count === images.length - 1) {
            setCount(0)
        } else setCount(count + 1);
    }
    function prevPhoto(e) {
        e.preventDefault()
        if (count === 0) {
            setCount(images.length - 1)
        } else setCount(count - 1);

    }
    return (
        <div  className='cooperation__wrapper'>
            <h2> С заботой о вас</h2>
            <div className='cooperation__worker' style={width > 625 ? {display:'none'} :{display:'flex'}}>
                {
                    staffArrayView.map(person =>
                        <div className='cooperation__person'>
                            <img alt='Фото' src={person.photo} />
                            <div className='person__info'>
                                <h3>{person.name}</h3>
                                <p>{person.info}</p>
                            </div>
                        </div>
                    )
                }
            </div>
            <div style={width < 625 ? {display:'flex'} : {display: ' none'}}className='button__person__wrapper'>
            <button  className='left-arrow' onClick={prevPerson}>
                    <Arrow style={firstPerson === 0 ? {display:'none'} : {display: 'block'}} className='left-arrow-svg' height='50px' />
                </button>
                <button  className='right-arrow' onClick={nextPerson}>
                    <Arrow style={lastPerson === 6 ? {display:'none'} : {display: 'block'}} className='right-arrow-svg' height='50px' />
                </button>
                </div>
            <div className='cooperation__about'>
                <h3> Наши <br /> принципы:</h3>
                <div className='about__item'>
                    <h4> Забота </h4>
                    <p> Уделяя внимание процессу производства на всех этапах,
                        мы заботимся о каждой семье, доставляя к
                        вашему столу здоровье.</p>
                </div>
                <div className='about__item'>
                    <h4> Ответсвенность </h4>
                    <p> Мы регулярно приглашаем в крестьянское хозяйство проверяющие органы,
                        демонстрируя соответствие актуальным ГОСТам.</p>
                </div>
                <div className='about__item'>
                    <h4> Профессионализм </h4>
                    <p> Профессионализм наших сотрудников заключается в любви к своему делу,
                        вниманию к деталям и экспертности.</p>
                </div>
            </div>
            <div className='about__photo' >
                <div className='button__wrapper'>
                    <button onClick={prevPhoto}>
                        <img src={leftArrow} alt="<" />
                    </button>
                    <button onClick={nextPhoto}>
                        <img src={rightArrow} alt=">" />
                    </button>
                </div>
                <img alt='Фото' className='about__img' src={images[count]} />

            </div>
            <div id="cooperation" className='cooperation__location'>
                <div className='location__info'>
                    <h2> Рядом с вами </h2>
                    <p>
                        Наша ферма находится в Брюховецком районе Краснодарского края,
                        менее чем в 100 км от Краснодара,
                        что позволяет нам доставлять продукцию регулярно,
                        в нужном объёме, не теряя свежесть и товарный вид.
                    </p>
                    <p>
                        Адрес: Краснодарский край, Брюховецкий район,
                        посёлок Лиманский, промзона
                    </p>
                    <button> ПЕРЕЗВОНИТЕ МНЕ</button>
                    <div className='location__links'>
                        <a href="https://api.whatsapp.com/send/?phone=79883689933"> WhatsApp</a>
                        <a href="tel:+79883689933"> +7 (988) 368 99 33</a>
                        <a href="mailto:lichkina.valera@yandex.ru"> lichkina.valera@yandex.ru</a>
                    </div>

                </div>
                <div className='location__map'>
                    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A2c140bd66f5031f25900d914eb8ca36c8e295bfca04e526ffd4e979107b2e278&amp;source=constructor"
                        width="100%" height="100%" frameborder="0" title='map'>

                    </iframe>
                </div>
            </div>
        </div>
    )
}