import bedro from '../images/photo/catalog/bedro.jpg';
import beskostnii_okorok from '../images/photo/catalog/beskostnii_okorok.jpg';
import beskostnoe from '../images/photo/catalog/beskostnoe.jpg';
import chicken from '../images/photo/catalog/chicken.jpg';
import farsh from '../images/photo/catalog/farsh.jpg';
import file from '../images/photo/catalog/file.jpg';
import golen from '../images/photo/catalog/golen.jpg';
import golova from '../images/photo/catalog/golova.jpg';
import grudka from '../images/photo/catalog/grudka.jpg';
import jeludok from '../images/photo/catalog/jeludok.jpg';
import jir from '../images/photo/catalog/jir.jpg';
import koja from '../images/photo/catalog/koja.jpg';
import krilo from '../images/photo/catalog/krilo.jpg';
import nogi from '../images/photo/catalog/nogi.jpg';
import okorok from '../images/photo/catalog/okorok.jpg';
import pechen from '../images/photo/catalog/pechen.jpg';
import serdce from '../images/photo/catalog/serdce.jpg';
import set_bulion from '../images/photo/catalog/set_bulion.jpg';
import shashlik from '../images/photo/catalog/shashlik.jpg';
import sheya_bez from '../images/photo/catalog/sheya_bez.jpg';
import sheya from '../images/photo/catalog/sheya.jpg';
import soup from '../images/photo/catalog/soup.jpg';



let catalog = [
    {   
        image: grudka,
        name: 'Грудка',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: bedro,
        name: 'Бедро',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: beskostnii_okorok,
        name: 'Бескостный окорочек',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: beskostnoe,
        name: 'Бескостное мясо',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: chicken,
        name: 'Тушка',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: farsh,
        name: 'Фарш',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: file,
        name: 'Филе грудки',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: golen,
        name: 'Голень',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: golova,
        name: 'Головы',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: jeludok,
        name: 'Желудки',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: jir,
        name: 'Жир',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: koja,
        name: 'Кожа',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: krilo,
        name: 'Крылья',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: nogi,
        name: 'Ноги',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: okorok,
        name: 'Окорочок',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: pechen,
        name: 'Печень',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: serdce,
        name: 'Сердце',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: set_bulion,
        name: 'Набор для бульона',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: shashlik,
        name: 'Набор для шашлыка',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: sheya_bez,
        name: 'Шеи без кожи',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: sheya,
        name: 'Шеи',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: soup,
        name: 'Набор для супа',
        info: [
            'Белки > 16 ',
            'Жиры >11',
            "Ккал/кДж 190/795,5",
            'Гост-328302'
        ]
    },
    {   
        image: false,
        name: '',
        info: [
        ]
    },
    {   
        image: false,
        name: '',
        info: [
        ]
    },
]
export default catalog;