import firstPerson from '../images/photo/staff/1.jpg'
import secondPerson from '../images/photo/staff/2.jpg'
import thirdPerson from '../images/photo/staff/3.jpg'
import fourthPerson from '../images/photo/staff/4.jpg'
import fifthPerson from '../images/photo/staff/5.jpg'
import sixthPerson from '../images/photo/staff/6.jpg';
 let staff = [
    {
        photo: sixthPerson,
        name: "Алексей Лычкин",
        info: "Основатель фермы",
    },
    {
        photo: firstPerson,
        name: "Валерия Лычкина",
        info: "Главный бухгалтер ",
    },
    {
        photo: secondPerson,
        name: "Игорь Ворохобин",
        info: "Ветеринарный Врач",
    },
    {
        photo: thirdPerson,
        name: "Наталья Петух",
        info: "Главный птицевод",
    },
    {
        photo: fourthPerson,
        name: "Муза Тихоненко",
        info: "Заведующая складом",
    },
    {
        photo: fifthPerson,
        name: "Наталья Журикова",
        info: "Заведующая цехом",
    },
    
]
export default staff;