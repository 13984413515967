import React from 'react';
import logo from '../images/logo.svg';
import '../styles/Navbar.scss';
import { useState } from 'react';
import burger from '../images/burger.svg';
import closeBurger from '../images/close-burger.svg';
import { Link } from 'react-scroll';


export default function Navbar({ open, width, popupOpen}) {
  const [openBurger, setOpenBurger] = useState(false)
  open(openBurger)
  console.log(openBurger)
 
  function openBurg() {
    setOpenBurger(!openBurger); 
  }

  return (
    <div className='navbar__wrapper'>

      <h2 style={width < 625 ? { display: 'none' } : { display: 'block' }} className='navbar__h2'><Link activeClass="active" smooth spy to="manufacture">Производство</Link></h2>
      <h2 style={width < 625 ? { display: 'none' } : { display: 'block' }} className='navbar__h2'><Link activeClass="active" smooth spy to="products">Продукция</Link></h2>
      <img className='navbar__logo' src={logo} alt={'chick'} style={openBurger ? {position:'fixed'} : {}}/>
      <h2 style={width < 625 ? { display: 'none' } : { display: 'block' }} className='navbar__h2'><Link activeClass="active" smooth spy to="history">История</Link></h2>
      <h2 style={width < 625 ? { display: 'none' } : { display: 'block' }} className='navbar__h2'><Link activeClass="active" smooth spy to="cooperation">Сотрудничество</Link></h2>
      <div style={width < 625 && !popupOpen ? { display: 'block' } : { display: 'none' }} >
        {openBurger ?
        <img  onClick={() => openBurg()} src={closeBurger} className='closeBurger' />
        : <img onClick={() => openBurg()} className='burger' src={burger} />}
      </div>
      <div style={openBurger ? { display: 'block' } : { display: 'none' }} className='burger__wrapper'>
        <div className='burger__title' style={openBurger  ? { display: 'block' } : { display: 'none' }} >
          <h3><Link activeClass="active" smooth spy to="manufacture" onClick={() => setOpenBurger(false)}>Производство</Link></h3>
          <h3><Link activeClass="active" smooth spy to="products" onClick={() => setOpenBurger(false)}>Продукция</Link></h3>
          <h3><Link activeClass="active" smooth spy to="history" onClick={() => setOpenBurger(false)}>История</Link></h3>
          <h3><Link activeClass="active" smooth spy to="cooperation"onClick={() => setOpenBurger(false)}>Сотрудничество</Link></h3>
        </div>
        <div className='burger__info' style={openBurger ? { display: 'flex' } : { display: 'none' }}>
          <a href="https://api.whatsapp.com/send/?phone=79883689933"> WhatsApp</a>
          <a href="tel:+79883689933"> +7 (988) 368 99 33</a>
          <a href="mailto:lichkina.valera@yandex.ru"> lichkina.valera@yandex.ru</a>
        </div>
      </div>
    </div>
  )
}