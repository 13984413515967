import React, { useState} from 'react';
import '../styles/Products.scss';
import cities from '../data/cities';
import { ReactComponent as Marker } from '../images/marker.svg'
import Popup from './Popup';
import icons from '../data/icons';
import catalog from '../data/catalog';

export default function Products({ find, width}) {
    
    let citiesArray = cities;
    let lastProducts;

    const [city, setCity] = useState()
    const [open, setOpen] = useState(false)

    const [isClick, setIsClick] = useState(false)

    function click() {
        setIsClick(!isClick)
    }

    if (width < 625) {
        lastProducts = 6
    } else {
        lastProducts = 12
    }

    let catalogArray = catalog;
    let catalogArraySliced = catalogArray.slice(0, lastProducts)
    let numberItemsOfAraray = catalogArray.length - catalogArraySliced.length


    function openPopup(props) {
        setCity(props)
        setOpen(true)
        find(true)
    }
    function closePopup() {
        setOpen(false)
        setCity(undefined)
        find(false)
    }
    return (
        <div id="products" className='products__wrapper'>
            <h2> Наша <br /> продукция </h2>
            <div className='products__list'>
                {(isClick ? catalogArray : catalogArraySliced).map(item => (
                    <div className='product__wrapper' style={!item.image ? {backgroundColor:'#F5F5F5'} : {}}>
                        <img  src={item.image} />
                        <div className='info__wrapper'>
                            <h3>{item.name}</h3>
                            {item.info.map(item => (
                                <li className='info__item'>
                                    {item}
                                </li>
                            ))}
                        </div>
                    </div>
                ))}
                
            </div>
            <button onClick={() => click()}> {(isClick ? 'Скрыть' : `Показать еще ${numberItemsOfAraray}`)} </button>
            <h2> Где можно купить <br /> нашу продукцию </h2>
            <div className='products__buy'>
                <Popup item={city} isOpen={open} closePopup={closePopup} />
                {citiesArray.map(city => (
                    <div>
                        <div className='city__wrapper' onClick={() => openPopup(city)} >
                            <Marker />
                            <p>{city.name}</p>
                        </div>
                    </div>

                ))}
            </div>
            <h2>Наши партнеры</h2>
            <div className='icons__wrapper'>
                {icons.map(icon => (
                    <div className='icon__wrapper'>
                        <img src={icon} />
                    </div>
                ))}
            </div>
        </div>
    )
}