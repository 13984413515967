import './App.css';
import Navbar from './components/Navbar'
import History from './components/History'
import Manufacture from './components/Manufacture';
import Products from './components/Products';
import Cooperation from './components/Сooperation';
import ears from './images/ears.svg';
import { useState, useEffect} from 'react';






function App() {
  
 const [popupIsOpen, setPopupIsOpen] = useState(false);
const [burgerIsOpen, setOpenBurger] = useState(false)

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

 function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const {height, width} = useWindowDimensions()

  if (popupIsOpen || burgerIsOpen === true) {
    document.querySelector('html').style.overflow = 'hidden'
  } else {
    document.querySelector('html').style.overflow = 'scroll'
  }

  function openBurger(props) {
    setOpenBurger(props)
  }
  function findIsOpen(props) {
    setPopupIsOpen(props)
  }
  return (
    <div className='app__wrapper'>
      <Navbar open={openBurger} popupOpen={popupIsOpen} width={width}/>
      <div className='components__wrapper'>
        <History />
        <Manufacture />
        <Products find={findIsOpen} width={width}/>
        <Cooperation width={width}/>
        <img className='ears' alt='ears' src={ears} />
      </div>
    </div>

  );
}

export default App;

