import React, {useState} from 'react';
import '../styles/Manufacture.scss'
import car from '../images/manufacture-image/car.svg';
import chick from '../images/manufacture-image/chick.svg';
import chicken from '../images/manufacture-image/chicken.svg';
import freezer from '../images/manufacture-image/freezer.svg';
import videoMain from '../video/Video_main.mp4';
import {ReactComponent as RightArrow} from '../images/right-arrow-yellow.svg';
import videoPopup from '../video/video_popup.mp4'
import closeButton from '../images/Close.svg';




export default function Manufacture() {
    const [openVideos, setOpenVideos] = useState(false);
    function openVideo(e) {
        e.preventDefault()
        setOpenVideos(!openVideos)
    }
    return (

        <div id="manufacture" className='manufacture__wrapper'>
            <h2>
                Ключевые этапы <br /> производства
            </h2>
            <div className='manufacture__info'>
                <div className='manufacture__block'>
                    <div className='block__title'>
                        СОДЕРЖАНИЕ
                    </div>
                    <div className='block__info'>
                        <p className='manufacture__text'>
                            Напольное содержание птицы позволяет
                            цыплятам наращивать естественную мышечную массу.
                            Благодаря такому подходу мясо становится упругим,
                            сохраняя свою форму.
                        </p>
                        <img className='manufacture__logo' src={chick} alt="цыпленок" />
                    </div>
                </div>
                <div className='manufacture__block'>
                    <div className='block__title'>
                        ОХЛАЖДЕНИЕ
                    </div>
                    <div className='block__info'>
                        <p className='manufacture__text'>
                            Качество мяса сохраняется в первозданном виде благодаря
                            нашей системе охлаждения. Тушка птицы погружается в ледяные
                            ванныи равномерно достигает температур 1-2 градуса, что позволяет
                            выдерживать срок хранения до 6 суток без использования консервантов.
                        </p>
                        <img className='manufacture__logo' src={freezer} alt="морозилка" />
                    </div>
                </div>
                <div className='manufacture__block'>
                    <div className='block__title'>

                        ФАБРИКА-БУТИК

                    </div>
                    <div className='block__info'>
                        <p className='manufacture__text'>
                            В отличие от крупных птицефабрик мы не гонимся за сроками,
                            а выращиваем каждого цыплёнка с любовью, заботясь о его питании
                            и комфорте. Такая забота позволяет вырастить птицу до убойного
                            размера без использования гормонов роста.
                        </p>
                        <img className='manufacture__logo' src={chicken} alt="курица" />
                    </div>
                </div>
                <div className='manufacture__block'>
                    <div className='block__title'>

                        ТРАНСПОРТИРОВКА

                    </div>
                    <div className='block__info'>
                        <p className='manufacture__text'>
                            Транспорт оборудован холодильными камерами,
                            что позволяет доставлять продукцию всегда свежей
                            в любую точку края.
                        </p>
                        <img className='manufacture__logo' src={car} alt="машина" />
                    </div>
                </div>
            </div>
            <div className='manufacture__video'>
            <img style={openVideos ? { display: 'block' } : { display: 'none' }} className={'closeButton'} src={closeButton} onClick={(e) => openVideo(e)}></img>
                <button style={openVideos === true ? {display:'none'} : {display: 'block'}} onClick={(e) => openVideo(e)}>
                    <RightArrow />
                </button>
                {openVideos === true ? <video src={videoPopup} autoPlay controls></video> :
                <video src={videoMain} autoPlay muted loop playsInline></video>}
    
            </div>
        </div>
    )
}