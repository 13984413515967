import React from 'react';
import '../styles/Popup.scss';
import closeButton from '../images/close-button.svg';
import marker from '../images/marker.svg';


export default function Popup({ item, isOpen, closePopup }) {
    return (
        <div>
            <div className='wrapper' style={isOpen ? { display: 'block' } : { display: 'none' }} onClick={() => closePopup(isOpen)}></div>
            <div className='popup__wrapper' style={isOpen ? { display: 'block' } : { display: 'none' }}>
                <div className='popup__header'>
                    <div className='popup__header_title'>
                        <img alt='marker' src={marker} />
                        <p>{item?.name}</p>
                        
                    </div>
                    
                    <img className='closeButton' alt='close' src={closeButton} onClick={() => closePopup(isOpen)} />
                </div>
                <p className='count__market'>Магазинов: {item?.markets?.length}</p>
                <div className='markets__wrapper'>
                    {item?.markets?.map(market => (
                        <div className='market__wrapper'>
                            <ul>{market?.name}</ul>
                            {market?.street?.map(item => (
                                <li>
                                    {item}
                                </li>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}